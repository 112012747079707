import React, { useState, useEffect } from "react";
import classes from "./Selection.module.scss";
import classNames from "classnames";
import Select from "react-dropdown-select";

const Selection = ({
  value,
  color = "#3a43ac",
  mTop = 10,
  mLeft = 0,
  placeholder,
  labelField = "display",
  valueField = "value",
  loading,
  disabled,
  classCustom,
  onClickHandle,
  additionalHandler,
  optionsValue,
  payload,
  index,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [val, setVal] = useState(value);
  const optionsDefault = [
    { id: 1, value: "selection1", display: "Selection 1" },
    { id: 2, value: "selection2", display: "Selection 2" },
    { id: 3, value: "selection3", display: "Selection 3" },
  ];

  const valueDefault = [{ id: 1, value: "selection1", display: "Selection 1" }];

  const handleSelection = (val) => {
    setVal(val[0]);
    console.log("test");
    console.log(value);
    console.log(val);
    if (additionalHandler) {
      if (payload) {
        if (val[0].value === value[0].value) {
          return;
        }
        additionalHandler(val[0], payload);
      } else {
        additionalHandler(val[0]);
      }
    }
  };

  useEffect(() => {
    setLoaded(false);
  }, []);

  const optionsSelect = {
    loading: loading,
    disabled: disabled,
    placeholder: placeholder,
    selectValues: [],
    searchBy: labelField,
    clearable: false,
    searchable: true,
    separator: false,
    handle: true,
    addPlaceholder: placeholder ? placeholder : "",
    labelField: labelField,
    valueField: valueField,
    color: color,
    keepSelectedInList: true,
    closeOnSelect: true,
    dropdownPosition: "bottom",
    direction: "ltr",
    dropdownHeight: "300px",
  };

  return (
    <Select
      style={{
        marginTop: mTop + "px",
        marginLeft: mLeft + "px",
      }}
      className={classNames(classes.selection, classCustom)}
      {...optionsSelect}
      onChange={handleSelection}
      onClick={onClickHandle}
      options={optionsValue ? optionsValue : optionsDefault}
      values={value ? value : [valueDefault[0]]}
    />
  );
};

export default Selection;
