import React, { Component } from "react";
import classes from "./Main.module.scss";
import Header from "../../components/Header";
import history from "../../utility/history";
import jwtDecode from "jwt-decode";
import { checkType } from "../../utility";

export default class Main extends Component {
  state = {
    userType: 1,
  };
  logoutHandle = () => {
    localStorage.removeItem("explorasa-cms");
    history.push("/login");
  };
  initial = async () => {
    let auth = JSON.parse(localStorage.getItem("pelicanX-cms"));
    if (auth) {
      const user = jwtDecode(auth.jwt);
      const type = await checkType(user.type);
      this.setState({ userType: type });
    }
  };
  componentDidMount() {
    this.initial();
  }
  render() {
    const { userType } = this.state;
    const { children, breadCrumbData } = this.props;
    return (
      <div className={classes.app}>
        <Header
          breadCrumbData={breadCrumbData}
          classCustom={classes.header}
          logoutHandle={this.logoutHandle}
          userType={userType}
        />
        <div className={classes.content}>{children}</div>
      </div>
    );
  }
}
