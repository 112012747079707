import React from "react";
import classes from "./ButtonApp.module.scss";
import classNames from "classnames";
import images from "../../commons/images";
const ButtonApp = ({
  classCustom,
  label = "Submit",
  border,
  addIcon,
  addWhite,
  onClickHandle,
  mTop = 10,
  mLeft = 0,
  disabled,
}) => {
  return (
    <div
      className={classNames(classes.btnPlc, classCustom)}
      style={{
        marginTop: mTop + "px",
        marginLeft: mLeft + "px",
      }}
    >
      <button
        className={classNames(classes.buttonApp, {
          [classes.borderBtn]: border,
          [classes.disabled]: disabled,
        })}
        onClick={disabled ? null : onClickHandle}
      >
        {addIcon && (
          <div className={classes.icon}>
            <img
              src={addWhite ? images.iconPlus : images.iconPlusRed}
              alt="add"
            />
          </div>
        )}
        {label}
      </button>
    </div>
  );
};

export default ButtonApp;
