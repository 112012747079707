import React, { Component } from "react";
import classNames from "./Search.module.scss";


class Search extends Component{
	state={
		markingCode : this.props.value,
		typingTimeout: 0
	}

	constructor(props){
		super(props);
		this.onMarkingCodeChange = this.onMarkingCodeChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		
		if (nextProps.value !== this.state.markingCode && nextProps.value === "") {
		  this.setState({ markingCode: nextProps.value }, this.props.onChangeHandle(""));
		}
	  }

	onMarkingCodeChange = (event) => {
		const self = this;
		
		if (self.state.typingTimeout) {
			clearTimeout(self.state.typingTimeout);
		 }
	 
		 self.setState({
			markingCode: event.target.value,
			typingTimeout: setTimeout(function () {
				self.props.onChangeHandle(self.state.markingCode)
			  }, 1000)
		 });
	}

	render(){
		
		return (
			<div className={classNames.searchBox}>
				 <input value={this.state.markingCode} type="text"  placeholder='Search by marking code'  onChange={this.onMarkingCodeChange} />
			</div>
		)
	}
	
}

export default Search;
