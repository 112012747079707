import React from "react";
import classNames from "classnames";
import classes from "./InputDate.module.scss";
import DatePicker from "react-date-picker";
import IconCalendar from "../IconCalendar";

const InputDate = ({
  mTop = 10,
  mLeft = 0,
  value,
  label,
  name,
  onChangeHandle,
  disabled,
  woicon,
}) => {
  return (
    <div
      style={{
        marginTop: mTop + "px",
        marginLeft: mLeft + "px",
      }}
      className={classes.inputDate}
    >
      <DatePicker
        name={name}
        disable={disabled}
        value={value}
        clearIcon={null}
        onChange={onChangeHandle}
        calendarIcon={woicon ? null : <IconCalendar />}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        format="dd-MM-yyyy"
      />
    </div>
  );
};

export default InputDate;
