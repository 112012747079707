import React from "react";
import classNames from "classnames";
import classes from "./ModalLogo.module.scss";
import Modal from "../Modal";
import history from "../../../utility/history";
import images from "../../../commons/images";

const ModalLogo = ({
  children,
  title,
  subTitle,
  goTo,
  clickOverlayHandle,
  error,
  classCustom,
}) => {
  const goToHandle = () => {
    history.push(goTo.path);
  };

  return (
    <Modal
      classParent={classes.container}
      classCustom={classNames(classes.modalWithLogo, classCustom)}
      onClickHandle={clickOverlayHandle}
    >
      {goTo && (
        <div
          className={classNames(classes.goTo, [
            goTo
              ? [goTo.direction === "left" ? classes.left : classes.right]
              : "",
          ])}
        >
          <a onClick={goTo.path ? goToHandle : goTo.changeStateHandle}>
            {goTo.title}
            <img src={images.iconArrowGrey} alt="arrowIcon" />
          </a>
        </div>
      )}
      <div className={classes.logoPlc}>
        <div className={classes.imgCont}>
          <img src={images.logoPelicanColor} alt="Pelican Express" />
        </div>
      </div>
      <div className={classes.content}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classNames(classes.subTitle, { [classes.error]: error })}>
          {subTitle}
        </p>
      </div>
      {children}
    </Modal>
  );
};

export default ModalLogo;
