import React, { Component } from "react";
import classes from "./ResetPassword.module.scss";
import FormResetPassword from "../../components/FormResetPassword";
import history from "../../utility/history";

import ModalSuccess from "../../components/Modals/ModalSuccess";
import { http } from "../../utility/http";

export default class ResetPassword extends Component {
  state = {
    forgotData: {
      newPassword: "",
    },
    showModal: false,
    activationData: null,
  };

  onChangeHandle = ({ target: { name, value } }) => {
    this.setState({
      forgotData: {
        ...this.state.forgotData,
        [name]: value,
      },
    });
  };

  submitHandler = async () => {
    const token = this.props.location.token;
    const params = {
      method: "POST",
      path: `auth/reset_pwd/verify`,
      data: {
        token,
        newPassword: this.state.forgotData.newPassword,
      },
    };
    const res = await http(params);
    console.log(res);
    if (res) {
      if (res.success) {
        this.setState({
          showModal: true,
        });
      }
    }
  };

  goToLogin = () => {
    history.push("/login");
  };

  componentDidMount() {
    const token = this.props.location.token;
    if (!token) {
      history.push("/login");
    }
  }

  render() {
    const { showModal } = this.state;
    return (
      <div className={classes.resetPassword}>
        {showModal ? (
          <ModalSuccess
            title="Reset Success!"
            msg="Your password has been Renewed."
            btnClickHandle={this.goToLogin}
          />
        ) : (
          <FormResetPassword
            submitHandle={this.submitHandle}
            onChangeHandle={this.onChangeHandle}
            data={this.state.forgotData}
            submitHandler={this.submitHandler}
          />
        )}
      </div>
    );
  }
}
