import React, { Component } from "react";
import history from "../../utility/history";
import { http } from "../../utility/http";

class ResetPassword extends Component {
  getVerify = async () => {
    const token = this.props.match.params.token;
    const config = {
      method: "GET",
      path: `auth/reset_pwd/validate_token/${token}`,
      showMessage: false,
    };
    const res = await http(config);
    console.log(res);
    if (res) {
      if (res.success) {
        history.push({
          pathname: "/reset-password",
          state: "forgot",
          token,
        });
      }
    }
  };
  componentDidMount() {
    this.getVerify();
  }
  render() {
    return <div></div>;
  }
}

export default ResetPassword;
