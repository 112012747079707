import React from "react";
import classes from "./TableHeader.module.scss";
import classNames from "classnames";
import images from "../../commons/images";

export const TableHeader = ({
  data,
  sortHandle,
  headerTable,
  sortingHandle,
}) => {
  return (
    <thead className={classes.tableHeader}>
      <tr>
        {sortHandle
          ? headerTable.map((header, index) => (
              <th
                key={index}
                className={classNames({
                  [classes.sort]: header.isActive,
                })}
              >
                <span
                  className={header.isSort ? classes.pointer : null}
                  onClick={() => sortingHandle(index)}
                >
                  {header.title}
                  {header.isSort && header.isActive && (
                    <span className={classes.arrowSort}>
                      <img
                        src={images.iconAsc}
                        alt="icon Sort"
                        className={header.sortASC ? "" : classes.desc}
                      />
                    </span>
                  )}
                </span>
              </th>
            ))
          : data.map((e, key) => <th key={key}>{e}</th>)}
      </tr>
    </thead>
  );
};

export default TableHeader;
