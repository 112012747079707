import React from "react";
import classes from "./InputWithLabel.module.scss";
import classNames from "classnames";
import InputApp from "../InputApp";

const InputWithLabel = ({
  required,
  label = "label",
  info,
  value,
  name,
  align = "left",
  placeholder,
  mTop = 10,
  mLeft = 0,
  type,
  classCustom,
  onChangeHandle,
  onChangeNumber,
  onClickForm,
  optional,
  children,
  isNumber,
  woSepataror,
  isCurrency,
  disabled,
  altInput,
  wZero,
  icon,
  srcIcon,
  alignIcon,
}) => {
  return (
    <div
      style={{
        marginTop: mTop + "px",
        marginLeft: mLeft + "px",
      }}
      className={classNames(classes.inputWithLabel, classCustom)}
    >
      <p>
        {label}
        {optional && <span>(this is optional)</span>}
        {required && <span className={classes.required}>*</span>}
      </p>
      <InputApp
        icon={icon}
        srcIcon={srcIcon}
        alignIcon={alignIcon}
        align={align}
        value={value}
        name={name}
        type={type}
        label={placeholder}
        info={info}
        onChangeHandle={onChangeHandle}
        onChangeNumber={onChangeNumber}
        onClickForm={onClickForm}
        altInput={altInput}
        isNumber={isNumber}
        wZero={wZero}
        woSepataror={woSepataror}
        isCurrency={isCurrency}
        disabled={disabled}
      />
      {children}
    </div>
  );
};

export default InputWithLabel;
