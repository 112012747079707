import React from "react";
import classes from "./BtnClose.module.scss";
import classNames from "classnames";
const BtnClose = ({ onClickHandle, width = "24px", color, classCustom }) => {
  return (
    <div
      onClick={onClickHandle}
      style={{ width: width, height: width }}
      className={classNames(classes.btnClose, classCustom)}
    >
      <span style={{ background: color }} onClick={onClickHandle} />
      <span style={{ background: color }} onClick={onClickHandle} />
    </div>
  );
};

export default BtnClose;
