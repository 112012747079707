import React from "react";
import classes from "./Loader.module.scss";
import classNames from "classnames";

const Loader = ({ classComponent }) => {
  return (
    <div className={classNames(classes.loaderContainer, classComponent)}>
      <span />
      <span />
      <span />
    </div>
  );
};
export default Loader;
