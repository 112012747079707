import React, { useRef } from "react";
import classes from "./RichText.module.scss";
import classNames from "classnames";
import "./RichText.css";
import { Editor } from "@tinymce/tinymce-react";

const TnCDraft = ({
  init,
  value,
  height = 500,
  onChangeHandler,
  disabled,
  classCustom,
}) => {
  const config = {
    height,
    menubar: false,
    plugins: ["paste wordcount"],
    toolbar: "",
  };

  return (
    <div className={classNames(classes.richText, classCustom)}>
      <Editor
        apiKey="f0yk6mprcocpdf4u42nvpxylh3pna372as8dl796hsg022jc"
        initialValue={init ? init : ''}
        value={value}
        disabled={disabled}
        init={config}
        onEditorChange={onChangeHandler}
      />
    </div>
  );
};

export default TnCDraft;
