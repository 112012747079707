import React from "react";
import classes from "./ModalState.module.scss";
import Modal from "../Modal";
import BtnClose from "../../../elements/BtnClose";
import images from "../../../commons/images";
import classNames from "classnames";

const ModalState = ({
  isSuccess = false,
  title = isSuccess ? "Successful!" : "Uppps!",
  errorMsg = "Something went wrong. Please try again!",
  successMsg,
  subtitle = isSuccess ? successMsg : errorMsg,
  classCustom,
  classParent,
  clickOverlayHandle,
  closeBtnHandle,
  wIcon,
  children,
}) => {
  return (
    <Modal
      classCustom={classNames(classes.modalState, classCustom)}
      onClickHandle={clickOverlayHandle}
      classParent={classParent}
    >
      <BtnClose classCustom={classes.btnClose} onClickHandle={closeBtnHandle} />
      {wIcon && (
        <div className={classes.imgCont}>
          <img src={isSuccess ? images.iconAllSet : images.imgFailed} alt="" />
        </div>
      )}
      <div className={classes.content}>
        <h4>{title}</h4>
        <p>{subtitle}</p>
      </div>
      {children}
    </Modal>
  );
};

export default ModalState;
