import logoPelican from "../assets/images/logo-pelican@3x.png";
import logoPelicanPre from "../assets/images/logo-pelican.png";
import logoPelicanColor from "../assets/images/icon-pelican-color@3x.png";
import logoPelicanColorPre from "../assets/images/icon-pelican-color.png";
import imgSlide1 from "../assets/images/img-slide-1@3x.png";
import imgSlide1Pre from "../assets/images/img-slide-2.png";
import imgSlide2 from "../assets/images/img-slide-2@3x.png";
import imgSlide2Pre from "../assets/images/img-slide-2.png";
import imgSlide3 from "../assets/images/img-slide-3@3x.png";
import imgSlide3Pre from "../assets/images/img-slide-3.png";

import iconShow from "../assets/images/icon-show@3x.png";
import iconShowPre from "../assets/images/icon-show.png";
import iconUnshow from "../assets/images/icon-unshow@3x.png";
import iconUnshowPre from "../assets/images/icon-unshow.png";
import iconLogout from "../assets/images/icon-logout@3x.png";
import iconLogoutPre from "../assets/images/icon-logout.png";
import iconPlus from "../assets/images/icon-plus@3x.png";
import iconPlusPre from "../assets/images/icon-plus.png";
import iconView from "../assets/images/icon-view@3x.png";
import iconViewPre from "../assets/images/icon-view.png";
import iconEdit from "../assets/images/icon-edit@3x.png";
import iconEditPre from "../assets/images/icon-edit.png";
import iconAsc from "../assets/images/icon-asc@3x.png";
import iconAscPre from "../assets/images/icon-asc.png";

import iconLock from "../assets/images/icon-lock@3x.png";
import iconLockPre from "../assets/images/icon-lock.png";
import iconEmail from "../assets/images/icon-email@3x.png";
import iconEmailPre from "../assets/images/icon-email.png";
import iconDate from "../assets/images/icon-date@3x.png";
import iconDatePre from "../assets/images/icon-date.png";
import iconChevronRed from "../assets/images/icon-chevron-red@3x.png";
import iconChevronRedPre from "../assets/images/icon-chevron-red.png";
import iconChevronPurple from "../assets/images/icon-chevron-purple@3x.png";
import iconChevronPurplePre from "../assets/images/icon-chevron-purple.png";

import iconIg from "../assets/images/icon-ig@3x.png";
import iconIgPre from "../assets/images/icon-ig.png";
import iconSearch from "../assets/images/icon-search@3x.png";
import iconSearchPre from "../assets/images/icon-search.png";
import iconNotFound from "../assets/images/icon-not-found@3x.png";
import iconNotFoundPre from "../assets/images/icon-not-found.png";
import iconUdara from "../assets/images/icon-udara@3x.png";
import iconUdaraPre from "../assets/images/icon-udara.png";

import iconMcSearch from "../assets/images/icon-mc-search@3x.png";
import iconMcSearchPre from "../assets/images/icon-mc-search.png";

export default {
  logoPelican,
  logoPelicanPre,
  logoPelicanColor,
  logoPelicanColorPre,
  imgSlide1,
  imgSlide1Pre,
  imgSlide2,
  imgSlide2Pre,
  imgSlide3,
  imgSlide3Pre,
  iconShow,
  iconShowPre,
  iconUnshow,
  iconUnshowPre,
  iconAsc,
  iconAscPre,
  iconLock,
  iconLockPre,
  iconEmail,
  iconEmailPre,
  iconDate,
  iconDatePre,
  iconLogout,
  iconLogoutPre,
  iconPlus,
  iconPlusPre,
  iconChevronRed,
  iconChevronRedPre,
  iconChevronPurple,
  iconChevronPurplePre,
  iconView,
  iconViewPre,
  iconEdit,
  iconEditPre,
  iconIg,
  iconIgPre,
  iconSearch,
  iconSearchPre,
  iconNotFound,
  iconNotFoundPre,
  iconUdara,
  iconUdaraPre,
  iconMcSearch,
  iconMcSearchPre,
};
