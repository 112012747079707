import moment from "moment";

export const checkType = (type) => {
  switch (type) {
    case "admin_sea":
      return 2;
    case "admin_air":
      return 3;
    default:
      return 1;
  }
};

export const formatNumber = (num) => {
  return String(num).replace(/(.)(?=(\d{3})+$)/g, "$1.");
};

export const formatNumberNew = (num) => {
  let newNumSplit = num.split(".");
  let comma = newNumSplit[newNumSplit.length - 1];
  let number = newNumSplit[0];

  let newNumber = number;
  let last = comma[comma.length - 1];
  let last2 = comma[comma.length - 2];

  if (comma !== "000") {
    if (last === "0") {
      comma = comma.slice(0, 2);
      if (last2 === "0") {
        comma = comma.slice(0, 1);
      }
    }
    newNumber = `${newNumber}.${String(comma)}`;
  }
  return newNumber;
};
export const formatNumberDisplay = (num) => {
  let newNumSplit = num.split(".");
  let comma = newNumSplit[newNumSplit.length - 1];
  let number = newNumSplit[0];

  let newNumber = String(number).replace(/(.)(?=(\d{3})+$)/g, "$1.");
  let last = comma[comma.length - 1];
  let last2 = comma[comma.length - 2];
  if (comma !== "000") {
    if (last === "0") {
      comma = comma.slice(0, 2);
      if (last2 === "0") {
        comma = comma.slice(0, 1);
      }
    }
    newNumber = `${newNumber},${String(comma)}`;
  }

  return newNumber;
};

export const formatDateTime = (date) => {
  return moment(date).format("DD MMM YYYY, hh:mm");
};

export const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const formatHttpDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const previewText = (value, maxChar) => {
  let text = value;
  if (value.length > maxChar) {
    text = value.substr(0, maxChar) + "...";
  }
  return text;
};

export const filterPrice = (price) => {
  return "Rp. " + String(price).replace(/(.)(?=(\d{3})+$)/g, "$1.");
};
