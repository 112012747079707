import React from "react";
import classes from "./InputDateLabel.module.scss";
import classNames from "classnames";
import InputDate from "../InputDate";
const InputDateLabel = ({ classCustom, value, label, onChangeHandle }) => {
  return (
    <div className={classNames(classes.inputDateLabel, classCustom)}>
      <p>{label}</p>
      <InputDate value={value} onChangeHandle={onChangeHandle} />
    </div>
  );
};

export default InputDateLabel;
