import React from "react";
import classes from "./HeaderContent.module.scss";
import classNames from "classnames";
import images from "../../commons/images";
import history from "../../utility/history";

const HeaderContent = ({
  back,
  title,
  subtitle,
  tooltips,
  textTips,
  children,
  classCustom,
}) => {
  return (
    <div className={classNames(classes.headerContent, classCustom)}>
      <div className={classes.content}>
        {back && (
          <div className={classes.imgCont} onClick={() => history.goBack()}>
            <img src={images.iconChevronPurple} alt="back" />
          </div>
        )}
        <div className={classes.title}>
          {title && <h2>{title}</h2>}
          {tooltips && (
            <div className={classes.tooltips}>
              <div className={classes.imgCont + " " + classes.info}>
                {/* <BlurImage src={iconInfo} smallSrc={iconInfoPre} /> */}
              </div>
              <div className={classes.textTips}>
                <h4>Info</h4>
                <p>{textTips}</p>
              </div>
            </div>
          )}
          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>
      {children && <div className={classes.children}>{children}</div>}
    </div>
  );
};

export default HeaderContent;
