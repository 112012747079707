import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import moment from "moment";
import classes from "./Dashboard.module.scss";
import classNames from "classnames";
import Layout from "../../layout/Main";
import TableItem from "../../components/TableItem";
import TotalAmount from "../../components/TotalAmount";
import ButtonApp from "../../elements/ButtonApp";
import HeaderContent from "../../elements/HeaderContent";
import Selection from "../../elements/Selection";
import history from "../../utility/history";
import Fade from "../../hoc/fade";
import { formatHttpDate } from "../../utility";
import { http } from "../../utility/http";
import { checkType } from "../../utility";

export default class Dashboard extends Component {
  state = {
    userType: 1,
    loaded: false,
    loadedTotal: false,
    dataOrder: null,
    dataTotal: null,
    loadingData: true,
    filterTotal: null,
    dataFilter: {
      page: 1,
      limit: 20,
      sort: "created_at,DESC",
      type: undefined,
      startDate: formatHttpDate(new Date()),
      endDate: formatHttpDate(new Date()),
    },
    headerTable: [
      {
        title: "Marking Code",
        value: "marking_code",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "Description",
        value: "",
        isSort: false,
        isActive: false,
        sortASC: false,
      },
      {
        title: "CTNS",
        value: "ctns",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "Warehouse Entry Date",
        value: "warehouse_entry",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "Weight (kg)",
        value: "weight",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "Cubic (cbm)",
        value: "cubic",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "Tracking No.",
        value: "receipt_number",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "ETA",
        value: "ETA",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "Date Created",
        value: "created_at",
        isSort: true,
        isActive: true,
        sortASC: false,
      },
      {
        title: "Status",
        value: "status",
        isSort: true,
        isActive: false,
        sortASC: false,
      },
      {
        title: "",
        value: "",
        isSort: false,
        isActive: false,
        sortASC: false,
      },
    ],
    changedSelection: false,
    dataSelection: [{ id: 1, value: "air", display: "Udara" }],
    selectionType: [
      { id: 1, value: "air", display: "Udara" },
      { id: 2, value: "sea", display: "Laut" },
    ],
    dataStatus: [{ id: 1, value: "delivery", dispplay: "Delivery" }],
    selectionStatus: [
      { id: 1, value: 0, display: "Delivery" },
      { id: 2, value: 1, display: "Arrived" },
    ],
  };

  initial = async () => {
    let auth = JSON.parse(localStorage.getItem("pelicanX-cms"));
    if (auth) {
      const user = jwtDecode(auth.jwt);
      const type = await checkType(user.type);
      console.log(user);
      let newDataFilter = { ...this.state.dataFilter };
      if (type === 2) {
        newDataFilter.type = "sea";
      } else {
        newDataFilter.type = "air";
      }
      this.setState({
        userType: type,
        dataFilter: newDataFilter,
      });
      if (type === 2) {
        this.setState({
          headerTable: [
            {
              title: "Marking Code",
              value: "marking_code",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "Description",
              value: "",
              isSort: false,
              isActive: false,
              sortASC: false,
            },
            {
              title: "CTNS",
              value: "ctns",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "Warehouse Entry Date",
              value: "warehouse_entry",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "Cubic (cbm)",
              value: "cubic",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "Weight (kg)",
              value: "weight",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "Tracking No.",
              value: "receipt_number",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "ETA",
              value: "ETA",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "Date Created",
              value: "created_at",
              isSort: true,
              isActive: true,
              sortASC: false,
            },
            {
              title: "Status",
              value: "status",
              isSort: true,
              isActive: false,
              sortASC: false,
            },
            {
              title: "",
              value: "",
              isSort: false,
              isActive: false,
              sortASC: false,
            },
          ],
        });
      }
    }
    await this.resetTotal();
  };

  getData = async () => {
    const { dataFilter } = this.state;
    let params = {
      method: "GET",
      path: `cargo`,
      query: dataFilter,
    };
    this.setState({ loadingData: true });
    const res = await http(params);
    console.log(res);
    if (res) {
      // this.setState({ loaded: false });
      if (res.success) {
        console.log("data");
        this.setState({
          dataOrder: res.response.payload,
          loaded: true,
          loadingData: false,
        });
      }
    }
  };

  handleNavigation = (page, limit, isLimit) => {
    const { dataFilter } = this.state;
    this.setState(
      {
        dataFilter: {
          ...dataFilter,
          page: isLimit ? 1 : page,
          limit,
        },
      },
      this.getData
    );
  };

  sortingHandle = async (index) => {
    const { headerTable, dataFilter } = this.state;
    let newHeader = [...headerTable];
    let oldState = newHeader[index].sortASC;
    if (!newHeader[index].isSort) {
      return;
    }
    for (let i = 0; i < newHeader.length; i++) {
      if (newHeader[i].isSort) {
        newHeader[i].isActive = false;
        newHeader[i].sortASC = false;
      }
    }
    newHeader[index].isActive = true;
    newHeader[index].sortASC = !oldState;
    console.log(newHeader[index].sortASC ? "true" : "false");
    this.setState(
      {
        headerTable: newHeader,
        dataFilter: {
          ...dataFilter,
          sort: `${newHeader[index].value},${
            newHeader[index].sortASC ? "ASC" : "DESC"
          }`,
        },
      },
      this.getData
    );
  };

  onChangeFilter = ({ target: { name, value } }) => {
    const { filterTotal } = this.state;
    const setData = (valueField) => {
      this.setState(
        {
          changedSelection: true,
          filterTotal: {
            ...filterTotal,
            [name]: valueField,
          },
          dataFilter: {
            ...this.state.dataFilter,
            [name]: formatHttpDate(valueField),
          },
        },
        () => {
          this.checkerHandler();
          this.getTotalAmount(
            formatHttpDate(this.state.filterTotal.startDate),
            formatHttpDate(this.state.filterTotal.endDate),
            this.state.filterTotal.markingCode,
          );
          this.getData();
        }
      );
    };
    setData(value);
  };

  onChangeMarkingDate = (val) => {
    const { filterTotal } = this.state;
    const { dataFilter } = this.state;

    this.setState({
        loadedTotal: false,
        changedSelection: true,
        filterTotal: {
          ...filterTotal,
          markingCode: val,
        },
        dataFilter: {
          ...dataFilter,
          marking_code : val,
        }
      },
      () => {
        this.getTotalAmount(
          formatHttpDate(this.state.filterTotal.startDate),
          formatHttpDate(this.state.filterTotal.endDate),
          val,
        );
        this.getData();
      }
    );

  }

  selectionTypeHandle = (val) => {
    console.log("hei");
    const { dataFilter } = this.state;
    this.setState({
      loadedTotal: false,
      changedSelection: true,
    });
    let newDataFilter = { ...dataFilter };
    newDataFilter.type = val.value;
    if (val.value === "sea") {
      this.setState({
        headerTable: [
          {
            title: "Marking Code",
            value: "marking_code",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Description",
            value: "",
            isSort: false,
            isActive: false,
            sortASC: false,
          },
          {
            title: "CTNS",
            value: "ctns",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Warehouse Entry Date",
            value: "warehouse_entry",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Cubic (cbm)",
            value: "cubic",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Weight (kg)",
            value: "weight",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Tracking No.",
            value: "receipt_number",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "ETA",
            value: "ETA",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Date Created",
            value: "created_at",
            isSort: true,
            isActive: true,
            sortASC: false,
          },
          {
            title: "Status",
            value: "status",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "",
            value: "",
            isSort: false,
            isActive: false,
            sortASC: false,
          },
        ],
      });
    } else {
      this.setState({
        headerTable: [
          {
            title: "Marking Code",
            value: "marking_code",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Description",
            value: "",
            isSort: false,
            isActive: false,
            sortASC: false,
          },
          {
            title: "CTNS",
            value: "ctns",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Warehouse Entry Date",
            value: "warehouse_entry",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Weight (kg)",
            value: "weight",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Cubic (cbm)",
            value: "cubic",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Tracking No.",
            value: "receipt_number",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "ETA",
            value: "ETA",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "Date Created",
            value: "created_at",
            isSort: true,
            isActive: true,
            sortASC: false,
          },
          {
            title: "Status",
            value: "status",
            isSort: true,
            isActive: false,
            sortASC: false,
          },
          {
            title: "",
            value: "",
            isSort: false,
            isActive: false,
            sortASC: false,
          },
        ],
      });
    }
    this.setState(
      {
        dataSelection: [{ ...val }],
        dataFilter: newDataFilter,
      },
      () => {
        this.getTotalAmount(
          formatHttpDate(this.state.filterTotal.startDate),
          formatHttpDate(this.state.filterTotal.endDate),
          this.state.filterTotal.markingCode,
        );
        this.getData();
      }
    );
  };

  selectionStatusHandle = (val, id, index) => {
    if (!id) {
      return;
    }
    console.log("jalan");
    this.updateStatus(id, val.value);
  };

  updateStatus = async (id_item, status) => {
    let params = {
      method: "PUT",
      path: `cargo/update_status/${id_item}/${status}`,
    };

    const res = await http(params);
    console.log(res);
    if (res) {
      if (res.success) {
        this.getData();
      }
    }
  };

  checkerHandler = () => {};

  resetTotal = () => {
    const now = formatHttpDate(new Date());
    const last = formatHttpDate(moment(now).subtract(30, "days"));
    this.getTotalAmount(last, now);
    this.setState(
      {
        dataFilter: {
          ...this.state.dataFilter,
          startDate: formatHttpDate(last),
          endDate: formatHttpDate(now),
          marking_code: "",
        },
        filterTotal: {
          startDate: new Date(last),
          endDate: new Date(now),
          markingCode: "",
        },
      },
      ()=>{
        this.getData()
      }
      
    );
  };

  resetBtnHandler = () => {
    console.log("jalan");
    this.setState(
      {
        dataSelection: [{ id: 1, value: "air", display: "Udara" }],
        changedSelection: false,
      },
      this.resetTotal
    );
  };

  getTotalAmount = async (startDate, endDate, marking_code="") => {
    const { dataFilter } = this.state;
    let params = {
      method: "GET",
      path: `cargo/get/total_count/${dataFilter.type}/${startDate}/${endDate}`,
    };
    if(marking_code !== ""){
      params.query = {marking_code}
    }
    this.setState({ loadedTotal: false });
    const res = await http(params);
    if (res) {
      if (res.success) {
        this.setState(
          {
            loadedTotal: true,
            dataTotal: res.response.payload.cargo,
          },
          () => console.log(this.state.dataTotal)
        );
      } else {
        this.setState(
          {
            loadedTotal: true,
            dataTotal: { total_weight: "0.000", total_cubic: "0.000" },
          },
          () => console.log(this.state.dataTotal)
        );
      }
    }
  };

  componentDidMount() {
    this.initial();
  }

  role = (userType) => {
    switch (userType) {
      case 2:
        return {
          title: "Laut",
          subtitle: "List of all item by Laut type",
        };
      case 3:
        return {
          title: "Udara",
          subtitle: "List of all item by Udara type",
        };
      default:
        return { title: "Items", subtitle: "List of all item" };
    }
  };

  render() {
    const {
      dataFilter,
      userType,
      loaded,
      loadedTotal,
      loadingData,
      headerTable,
      dataOrder,
      filterTotal,
      dataTotal,
      dataSelection,
      selectionType,
      changedSelection,
      dataStatus,
      selectionStatus,
    } = this.state;
    return (
      <Layout
        breadCrumbData={{
          title: ["Dashboard"],
          path: [""],
        }}
      >
        <div className={classes.dashboard}>
          <HeaderContent
            classCustom={classNames(classes.headerContent, {
              [classes.notSuper]: userType !== 1,
            })}
            title={this.role(userType).title}
            subtitle={this.role(userType).subtitle}
          >
            {userType === 1 && (
              <div className={classes.selectionType}>
                <Selection
                  mTop={0}
                  value={dataSelection}
                  optionsValue={selectionType}
                  additionalHandler={this.selectionTypeHandle}
                />
              </div>
            )}
            <ButtonApp
              mTop={0}
              addIcon={true}
              addWhite={true}
              label="Add New Data"
              classCustom={classes.addBtn}
              onClickHandle={() => {
                userType === 1
                  ? history.push({
                      pathname: "/add-data",
                      type: dataSelection[0].value,
                    })
                  : history.push("/add-data");
              }}
            />
          </HeaderContent>
          <Fade loaded={loadedTotal} wLoader={true}>
            <TotalAmount
              userType={userType}
              changedSelection={changedSelection}
              dataSelection={dataSelection}
              data={dataTotal}
              filterTotal={filterTotal}
              onChangeFilter={this.onChangeFilter}
              resetBtnHandler={this.resetBtnHandler}
              onChangeMarkingDate={this.onChangeMarkingDate}
            />
          </Fade>
          <Fade loaded={loaded} wLoader={true}>
            <Fade loaded={!loadingData} wLoader={true}>
              <TableItem
                data={dataOrder}
                dataFilter={dataFilter}
                headerTable={headerTable}
                sortingHandle={this.sortingHandle}
                handleNavigation={this.handleNavigation}
                dataStatus={dataStatus}
                selectionStatus={selectionStatus}
                selectionStatusHandle={this.selectionStatusHandle}
              />
            </Fade>
          </Fade>
        </div>
      </Layout>
    );
  }
}
