import React from "react";
import classes from "./BreadCrumb.module.scss";
import classNames from "classnames";
import history from "../../utility/history";
import images from "../../commons/images";

const Breadcrumb = ({ breadCrumbData, classCustom }) => {
  return (
    <div className={classNames(classes.breadCrumb, classCustom)}>
      <div className={classes.content}>
        {breadCrumbData.title.map((item, index) => (
          <div
            key={`${index}-breadCrumb`}
            className={classNames(classes.item, {
              [classes.active]: index === breadCrumbData.title.length - 1,
            })}
          >
            <p
              onClick={() => {
                if (breadCrumbData.path[index]) {
                  history.push(breadCrumbData.path[index]);
                }
              }}
            >
              {item}
            </p>
            {index !== breadCrumbData.title.length - 1 && (
              <div className={classes.arrow}>
                <img src={images.iconChevronBlack} alt="" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
