import React from "react";
import classes from "./FormLogin.module.scss";
import ModalLogo from "../Modals/ModalLogo/ModalLogo";
import InputApp from "../../elements/InputApp";
import ButtonApp from "../../elements/ButtonApp";
import images from "../../commons/images";
import history from "../../utility/history";

const FormLogin = ({ data, onChangeHandle, submitHandler }) => {
  return (
    <ModalLogo
      classCustom={classes.modalLogin}
      subTitle={data.feedback}
      error={data.feedback ? true : false}
    >
      <form
        className={classes.formLogin}
        onSubmit={(e) => {
          e.preventDefault();
          {
            /* submitHandle(); */
          }
        }}
      >
        {data.feedback && <p className={classes.error}>{data.feedback}</p>}
        <InputApp
          type="email"
          label="example@mail.com"
          name="email"
          value={data.email}
          mTop={20}
          icon={true}
          srcIcon={images.iconEmail}
          classCustom={classes.input}
          onChangeHandle={onChangeHandle}
        />
        <InputApp
          type="password"
          label="•••••••••"
          name="password"
          value={data.password}
          classCustom={classes.input}
          onChangeHandle={onChangeHandle}
          srcIcon={images.iconLock}
          multipleIcon={true}
        />
        {/* <a className={classes.forgot}>Forgot Password?</a> */}
        <ButtonApp
          label="Login"
          mTop={20}
          classCustom={classes.btnSubmit}
          onClickHandle={submitHandler}
        />
      </form>
      <a
        onClick={() => history.push("/forgot-password")}
        className={classes.forgot}
      >
        Forgot Password?
      </a>
      <p className={classes.copy}>
        Copyright © 2020 Pelican Express. <span /> All Rights Reserved.
      </p>
    </ModalLogo>
  );
};

export default FormLogin;
