import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import classes from "./AddNew.module.scss";
import classNames from "classnames";
import Layout from "../../layout/Main";
import FormAddNew from "../../components/FormAddNew";
import ModalState from "../../components/Modals/ModalState";
import HeaderContent from "../../elements/HeaderContent";
import ButtonApp from "../../elements/ButtonApp";
import ProgressBar from "../../elements/ProgressBar";
import { formatDateTime, formatDate, checkType } from "../../utility";
import { http } from "../../utility/http";
import history from "../../utility/history";

export default class AddNew extends Component {
  state = {
    loaded: false,
    userType: 1,
    submitConfig: {
      isSuccess: false,
      modalState: false,
      submitDisabled: true,
      feedback: "",
      progress: {
        active: false,
        count: 0,
      },
    },
    newData: {
      marking_code: "",
      type: {
        is_sea: true,
        is_air: false,
      },
      warehouse_entry: "",
      ETA: "",
      weight: "",
      cubic: "",
      ctns: "",
      receipt_number: "",
      description: "",
    },
  };

  changeModalState = (state) => {
    const { submitConfig } = this.state;
    this.setState({
      submitConfig: {
        ...submitConfig,
        modalState: state,
      },
    });
  };

  changeDataProgress = (name, value) => {
    const { submitConfig } = this.state;
    let newConfig = { ...submitConfig };
    if (name === "progress") {
      newConfig.progress.count = value;
    } else {
      newConfig.progress.active = value;
    }

    this.setState({
      submitConfig: newConfig,
    });
  };

  submitHandler = async () => {
    const { submitDisabled } = this.state.submitConfig;
    const {
      marking_code,
      type,
      warehouse_entry,
      ETA,
      weight,
      cubic,
      ctns,
      receipt_number,
      description,
    } = this.state.newData;
    let newData = {
      marking_code: marking_code.replace(/\s/g, ""),
      type: type.is_sea ? "sea" : "air",
      warehouse_entry: formatDateTime(warehouse_entry),
      ETA: formatDate(ETA),
      weight,
      cubic,
      ctns,
      receipt_number,
      description,
    };

    let params = {
      method: "POST",
      path: `cargo`,
      data: newData,
    };

    if (submitDisabled) {
      return;
    }

    this.changeDataProgress("state", true);
    const res = await http(params, true, this.changeDataProgress);
    if (res) {
      this.changeDataProgress("state", false);
      this.changeDataProgress("progress", 0);
      if (res.success) {
        this.changeModalState(true);
        this.setState({
          submitConfig: {
            ...this.state.submitConfig,
            isSuccess: true,
          },
        });
      } else {
        this.setState({
          submitConfig: {
            ...this.state.submitConfig,
            feedback: res.response.response.data.message,
          },
        });
      }
    }
  };
  onChangeHandle = ({ target: { name, value } }) => {
    const { newData } = this.state;
    const setData = (valueField) => {
      this.setState(
        {
          newData: {
            ...newData,
            [name]: valueField,
          },
        },
        this.checkerHandler
      );
    };
    if (name === "warehouse_entry" || name === "ETA") {
      setData(new Date(value));
    } else if (name === "type") {
      const { type } = this.state.newData;
      if (value === "sea") {
        type.is_sea = true;
        type.is_air = false;
      } else {
        type.is_sea = false;
        type.is_air = true;
      }
      setData(type);
    } else {
      setData(value);
    }
  };

  resetHandler = () => {
    this.setState({
      newData: {
        marking_code: "",
        type: {
          is_sea: true,
          is_air: false,
        },
        warehouse_entry: "",
        ETA: "",
        weight: "",
        cubic: "",
        ctns: "",
        description: "",
      },
    });
  };

  goTo = () => {
    history.push("/");
    this.resetHandler();
  };

  checkerHandler = () => {
    const {
      marking_code,
      warehouse_entry,
      ETA,
      weight,
      cubic,
      ctns,
      description,
      receipt_number,
    } = this.state.newData;

    const setConfig = (state, feedback) => {
      this.setState({
        submitConfig: {
          ...this.state.submitConfig,
          submitDisabled: state,
          feedback,
        },
      });
    };

    if (receipt_number) {
      if (receipt_number.length > 20) {
        return setConfig(true, "maximal Tracking No. is 20 character.");
      }
    }
    if (
      (marking_code, warehouse_entry, ETA, weight, cubic, ctns, description)
    ) {
      setConfig(false, "");
    } else {
      setConfig(true, "");
    }
  };

  initial = async () => {
    let auth = JSON.parse(localStorage.getItem("pelicanX-cms"));
    let newData = { ...this.state.newData };
    if (auth) {
      const user = jwtDecode(auth.jwt);
      const type = await checkType(user.type);
      console.log(type);
      if (type === 3 || this.props.location.type === "air") {
        newData.type = {
          is_sea: false,
          is_air: true,
        };
        this.setState({
          newData,
        });
      }
      this.setState({ userType: type, loaded: true });
    }
  };

  componentDidMount() {
    this.initial();
  }

  render() {
    const { loaded, userType, submitConfig, newData } = this.state;
    return (
      <Layout>
        <div className={classes.addNew}>
          <HeaderContent title="Add New Data" back={true} />
          {loaded && (
            <FormAddNew
              userType={userType}
              data={newData}
              onChangeHandle={this.onChangeHandle}
            />
          )}
          {submitConfig.feedback && (
            <p className={classes.error}>{submitConfig.feedback}</p>
          )}

          <div
            className={classNames(classes.submitPlc, {
              [classes.disabled]: submitConfig.submitDisabled,
            })}
          >
            <ButtonApp
              mTop={0}
              label="Cancel"
              border={true}
              onClickHandle={() => history.push("/")}
            />
            <ButtonApp
              mTop={0}
              label="Save"
              onClickHandle={this.submitHandler}
            />
          </div>
          {submitConfig.modalState && (
            <ModalState
              isSuccess={submitConfig.isSuccess}
              errorMsg={
                submitConfig.isSuccess ? undefined : submitConfig.feedback
              }
              closeBtnHandle={this.goTo}
              successMsg={"Data has been added!"}
            />
          )}
          {submitConfig.progress.active && (
            <ProgressBar count={submitConfig.progress.count} />
          )}
        </div>
      </Layout>
    );
  }
}
