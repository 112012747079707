import React from 'react'
import classes from './ProgressBar.module.scss'

const ProgressBar = ({count, fullPage}) => {
  return (
    <div className={classes.progress}>
      <div className={classes.container}>
        <div className={classes.progressBar}>
          <span style={{width: count + `%`}}/>
        </div>
        <p>{count}%</p>
      </div>
    </div>
  )
}

export default ProgressBar