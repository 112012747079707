import React from "react";
import classes from "./Modal.module.scss";
import classNames from "classnames";

const Modal = ({ classParent, classCustom, children, onClickHandle }) => {
  return (
    <div
      className={classNames(classes.modal, classParent)}
      onClick={onClickHandle}
    >
      <div className={classNames(classes.modalContent, classCustom)}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
