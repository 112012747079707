import React, { Component } from "react";
import classes from "./Login.module.scss";
import FormLogin from "../../components/FormLogin";
import ModalState from "../../components/Modals/ModalState";
import ButtonApp from "../../elements/ButtonApp";
import { http } from "../../utility/http";
import history from "../../utility/history";

export default class Login extends Component {
  state = {
    loginData: {
      email: "",
      password: "",
      feedback: "",
    },
    activationData: null,
  };

  onChangeHandle = ({ target: { name, value } }) => {
    this.setState({
      loginData: {
        ...this.state.loginData,
        [name]: value,
      },
    });
  };

  submitHandler = async () => {
    const { loginData } = this.state;
    const { email, password } = loginData;
    let params = {
      method: "POST",
      path: `auth/loginCMS`,
      data: {
        email,
        password,
      },
    };
    this.setState({
      loginData: {
        ...this.state.loginData,
        feedback: "",
      },
    });
    const res = await http(params);
    console.log(res);
    if (res) {
      if (res.success) {
        const res = await http(params);
        if (res) {
          if (res.success) {
            localStorage.setItem(
              "pelicanX-cms",
              JSON.stringify(res.response.payload)
            );
            history.push("/");
          }
        }
      } else {
        this.setState({
          loginData: {
            ...this.state.loginData,
            feedback: res.response.response.data.message,
          },
        });
      }
    }
  };

  render() {
    return (
      <div className={classes.login}>
        <FormLogin
          submitHandle={this.submitHandle}
          onChangeHandle={this.onChangeHandle}
          submitHandler={this.submitHandler}
          data={this.state.loginData}
        />
      </div>
    );
  }
}
