import React from "react";
import images from "../../commons/images";
import classes from "./IconCalendar.module.scss";
import classNames from "classnames";

const IconCalendar = ({ classCustom, width = 24, height = 24 }) => {
  return (
    <div
      className={classNames(classes.iconCalendar, classCustom)}
      style={{ width, height }}
    >
      <img src={images.iconDate} alt="date" />
    </div>
  );
};

export default IconCalendar;
