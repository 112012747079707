import React, { Component } from "react";
import classes from "./ForgotPassword.module.scss";
import FormForgotPassword from "../../components/FormForgotPassword";
import ModalSuccess from "../../components/Modals/ModalSuccess";
import history from "../../utility/history";
import { http } from "../../utility/http";

export default class Login extends Component {
  state = {
    forgotData: {
      email: "",
      feedback: "",
    },
    activationData: null,
    showModal: false,
  };

  onChangeHandle = ({ target: { name, value } }) => {
    this.setState({
      forgotData: {
        ...this.state.forgotData,
        [name]: value,
      },
    });
  };

  submitHandler = async () => {
    const params = {
      method: "POST",
      path: `auth/reset_pwd/request`,
      data: {
        email: this.state.forgotData.email,
      },
    };
    this.setState({
      forgotData: {
        ...this.state.forgotData,
        feedback: "",
      },
    });
    const res = await http(params);
    console.log(res);
    if (res) {
      if (res.success) {
        this.setState({
          showModal: true,
        });
      } else {
        this.setState({
          forgotData: {
            ...this.state.forgotData,
            feedback: res.response.response.data.message,
          },
        });
      }
    }
  };

  goToLogin = () => {
    history.push("/login");
  };

  render() {
    const { showModal } = this.state;
    return (
      <div className={classes.forgot}>
        {showModal ? (
          <ModalSuccess
            title="Email Sent!"
            msg="Please check your email and follow further instructions"
            btnClickHandle={this.goToLogin}
          />
        ) : (
          <FormForgotPassword
            submitHandle={this.submitHandle}
            onChangeHandle={this.onChangeHandle}
            data={this.state.forgotData}
            onSubmitHandle={this.submitHandler}
          />
        )}
      </div>
    );
  }
}
