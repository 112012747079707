import React, { useState, useEffect } from "react";
import classes from "./RadioWithLabel.module.scss";
import classNames from "classnames";
import RadioBtn from "../RadioBtn";

const RadioWithLabel = ({
  classCustom,
  label = "Select",
  labels = ["label"],
  data,
  onChangeRadio,
  userType,
}) => {
  const [array, setArray] = useState([]);
  useEffect(() => {
    let newArray = [];
    for (var i = 0; i < labels.length; i++) {
      newArray.push(labels[i]);
    }
    setArray(newArray);
  }, []);
  return (
    <div className={classNames(classes.radioLabel, classCustom)}>
      <p>{label}</p>
      <div className={classes.container}>
        {data.map((item, index) => (
          <RadioBtn
            key={index}
            label={item.label}
            isSelected={item.isSelected}
            onClickHandle={() => onChangeRadio(index)}
            disabled={
              userType === 1
                ? false
                : userType === 2
                ? index === 1
                : index === 0
            }
          />
        ))}
      </div>
    </div>
  );
};

export default RadioWithLabel;
