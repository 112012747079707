import React from "react";
import classes from "./TableItem.module.scss";
import classNames from "classnames";
import htmlToText from "html-to-text";
import TableHeader from "../../components/TableHeader";
import TableFooter from "../../components/TableFooter";
import { formatDate, formatNumberDisplay } from "../../utility";
import images from "../../commons/images";
import history from "../../utility/history";
import Selection from "../../elements/Selection";

const content = (
  data,
  dataFilter,
  tableLength,
  selectionStatus,
  selectionStatusHandle
) => {
  return (
    <>
      {data.length ? (
        <>
          {data.map((item, index) => {
            return (
              <tr key={index} className={classes.content}>
                <td>{item.marking_code}</td>
                <td className={classes.desc}>
                  <p>{htmlToText.fromString(item.description)}</p>
                </td>
                <td>{item.ctns ? formatNumberDisplay(item.ctns) : "-"}</td>
                <td>{formatDate(item.warehouse_entry * 1000)}</td>
                {dataFilter.type === "sea" ? (
                  <>
                    <td>{formatNumberDisplay(item.cubic)}</td>
                    <td>{formatNumberDisplay(item.weight)}</td>
                  </>
                ) : (
                  <>
                    <td>{formatNumberDisplay(item.weight)}</td>
                    <td>{formatNumberDisplay(item.cubic)}</td>
                  </>
                )}
                <td>{item.receipt_number ? item.receipt_number : "-"}</td>
                <td>{formatDate(item.ETA * 1000)}</td>
                <td>{formatDate(item.created_at * 1000)}</td>
                <td
                  className={classNames(classes.status, {
                    [classes.arrived]: item.status,
                  })}
                >
                  <Selection
                    classCustom={classes.selectionStatus}
                    value={
                      item.status === 1
                        ? [{ ...selectionStatus[1] }]
                        : [{ ...selectionStatus[0] }]
                    }
                    optionsValue={selectionStatus}
                    additionalHandler={selectionStatusHandle}
                    index={index}
                    payload={item.id_item}
                  />
                </td>
                <td>
                  <td>
                    <div className={classes.action}>
                      <div
                        className={classes.imgAction}
                        onClick={() =>
                          history.push(`/edit-data/${item.id_item}`)
                        }
                      >
                        <img src={images.iconEdit} alt="edit" />
                      </div>
                    </div>
                  </td>
                </td>
              </tr>
            );
          })}
        </>
      ) : (
        <>
          <tr className={classes.content}>
            <td className={classes.notFound} colSpan={tableLength}>
              No data founded.
            </td>
          </tr>
        </>
      )}
    </>
  );
};

const TableItem = ({
  data,
  dataFilter,
  headerTable,
  sortingHandle,
  handleNavigation,
  selectionStatus,
  selectionStatusHandle,
}) => {
  return (
    <div className={classes.tableItem}>
      <table>
        <TableHeader
          headerTable={headerTable}
          sortHandle={true}
          sortingHandle={sortingHandle}
        />
        <tbody>
          {data.rows &&
            content(
              data.rows,
              dataFilter,
              headerTable.length,
              selectionStatus,
              selectionStatusHandle
            )}
        </tbody>
        <TableFooter
          name={"Order"}
          data={data}
          handleNavigation={handleNavigation}
          lengthTable={headerTable.length}
        />
      </table>
    </div>
  );
};

export default TableItem;
