import React from "react";
import classes from "./FormForgotPassword.module.scss";
import ModalLogo from "../Modals/ModalLogo/ModalLogo";
import InputApp from "../../elements/InputApp";
import ButtonApp from "../../elements/ButtonApp";
import images from "../../commons/images";

const FormForgotPassword = ({ data, onChangeHandle, onSubmitHandle }) => {
  return (
    <ModalLogo
      classCustom={classes.modalForgot}
      title="Forgot Password"
      subTitle={
        data.feedback
          ? data.feedback
          : "Please enter your email to reset your password"
      }
      error={data.feedback ? true : false}
    >
      <form
        className={classes.formForgot}
        onSubmit={(e) => {
          e.preventDefault();
          {
            /* submitHandle(); */
          }
        }}
      >
        <InputApp
          type="email"
          label="example@mail.com"
          name="email"
          value={data.email}
          mTop={0}
          icon={true}
          srcIcon={images.iconEmail}
          classCustom={classes.input}
          onChangeHandle={onChangeHandle}
        />
        {/* <a className={classes.forgot}>Forgot Password?</a> */}
        <ButtonApp
          label="Send Verification"
          mTop={15}
          classCustom={classes.btnSubmit}
          onClickHandle={onSubmitHandle}
        />
      </form>
      <p className={classes.copy}>
        Copyright © 2020 Pelican Express. <span /> All Rights Reserved.
      </p>
    </ModalLogo>
  );
};

export default FormForgotPassword;
