import React from "react";
import classes from "./TableFooter.module.scss";
import images from "../../commons/images";
import classNames from "classnames";

const TableFooter = ({ data, handleNavigation, lengthTable, name }) => {
  const prevButton = () => {
    return (
      <div
        className={classNames(classes.btnNav, classes.prev, {
          [classes.disabled]: data.currentPage === data.previousPage,
        })}
        onClick={
          data.currentPage === data.previousPage
            ? null
            : () => handleNavigation(data.previousPage, data.rowPerPage)
        }
      >
        <img src={images.iconChevronPurple} alt="prev page" />
      </div>
    );
  };

  const nextButton = () => {
    return (
      <div
        className={classNames(classes.btnNav, classes.next, {
          [classes.disabled]: data.currentPage === data.nextPage,
        })}
        onClick={
          data.currentPage === data.nextPage
            ? null
            : () => handleNavigation(data.nextPage, data.rowPerPage)
        }
      >
        <img src={images.iconChevronPurple} alt="next page" />
      </div>
    );
  };

  return (
    <tfoot className={classes.tableFooter}>
      <tr>
        <td colSpan={lengthTable}>
          <div className={classes.actionPlc}>
            <div className={classes.info}>
              <p>Record per page</p>
              <select
                className={classes.recordSelection}
                value={data.rowPerPage}
                onChange={({ target: { value } }) =>
                  handleNavigation(data.currentPage, value, true)
                }
              >
                <option selected={true} value={20}>
                  20
                </option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <p>
                {data.info} {`${name}`}
              </p>
            </div>
            <div className={classes.btnAction}>
              {prevButton()}
              {data.navCounter && <p>{data.navCounter}</p>}
              {nextButton()}
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default TableFooter;
