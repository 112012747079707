import React, { useState, useEffect } from "react";
import "rc-datepicker/lib/style.css";
import classes from "./InputApp.module.scss";
import classNames from "classnames";
import images from "../../commons/images";
import NumberFormat from "react-number-format";
import { DatePicker } from "rc-datepicker";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { formatDate } from "../../utility";

const InputApp = ({
  isBorder,
  type = "text",
  value,
  label,
  info,
  name,
  mTop = 10,
  mLeft = 0,
  align = "left",
  icon = type === "password" ? true : false,
  multipleIcon,
  alignIcon = multipleIcon ? "right" : type === "password" ? "right" : "left",
  srcIcon = images.iconSearch,
  src2Icon = images.iconCloseCircle,
  onChangeHandle,
  onChangeNumber,
  onClickForm,
  clickIconHandle,
  clickIconHandle2,
  classCustom,
  children,
  altInput,
  isNumber,
  wZero,
  filter,
  woSepataror,
  isCurrency,
  disabled,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const handleShowPicker = (state) => {
    console.log("test");
    if (disabled) {
      return;
    }
    setShowPicker(state);
  };

  const handlePickDate = (date) => {
    // onChangeHandle({ target: { name: name, value: date } });
    onChangeHandle(formatDate(date));
    handleShowPicker(false);
  };

  const noFilter = moment().toDate();

  const toggleShowPass = () => {
    setShowPass(!showPass);
  };
  return (
    <>
      <div
        style={{
          marginTop: mTop + "px",
          marginLeft: mLeft + "px",
        }}
        className={classNames(
          classes.inputPlc,
          {
            [classes.border]: isBorder,
          },
          icon && [
            multipleIcon
              ? classes.multiple
              : [
                  alignIcon === "left"
                    ? classes.alignIconLeft
                    : classes.alignIconRight,
                ],
          ],
          classCustom,
          { [classes.wIcon]: icon }
        )}
      >
        {icon && (
          <div
            className={classNames(classes.imgCont, {
              [classes.password]: type === "password",
            })}
            onClick={
              multipleIcon
                ? clickIconHandle
                : type === "password"
                ? toggleShowPass
                : clickIconHandle
                ? clickIconHandle
                : ""
            }
          >
            <img
              src={
                multipleIcon
                  ? srcIcon
                  : type === "password"
                  ? showPass
                    ? images.iconUnshow
                    : images.iconShow
                  : srcIcon
              }
              alt="icon"
            />
          </div>
        )}
        {multipleIcon && (
          <div
            className={classNames(classes.imgCont, classes.imgCont2)}
            onClick={
              type === "password"
                ? toggleShowPass
                : clickIconHandle2
                ? clickIconHandle2
                : ""
            }
          >
            <img
              src={
                type === "password"
                  ? showPass
                    ? images.iconUnshow
                    : images.iconShow
                  : src2Icon
              }
              alt="icon"
            />
          </div>
        )}

        {isNumber ? (
          <NumberFormat
            type={type}
            value={value}
            placeholder={label}
            thousandSeparator={woSepataror ? "" : "."}
            decimalSeparator=","
            prefix={isCurrency ? "Rp. " : ""}
            isAllowed={(values) => !disabled}
            isNumericString={true}
            decimalScale={3}
            allowLeadingZeros={wZero ? true : false}
            onValueChange={(values) =>
              onChangeNumber({ target: { name, value: values.value } })
            }
          />
        ) : (
          <>
            {altInput ? (
              <>
                {altInput === "date" && (
                  <>
                    <input
                      type={type}
                      value={value}
                      name={name}
                      placeholder={label}
                      style={{ textAlign: align }}
                      onClick={() => handleShowPicker(true)}
                      disabled={disabled}
                    />

                    {showPicker && (
                      <div
                        className={classes.datePicker}
                        onMouseLeave={() => handleShowPicker(false)}
                      >
                        <DatePicker
                          style={{ zIndex: 3 }}
                          onChange={(e) => {
                            console.log(e);
                            handlePickDate(e);
                          }}
                          value={value}
                          name="date"
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <input
                className={icon ? classes.wIcon : null}
                type={showPass ? "text" : type}
                value={value}
                name={name}
                placeholder={label}
                style={{ textAlign: align }}
                onChange={onChangeHandle}
                onClick={onClickForm}
                disabled={disabled}
              />
            )}
          </>
        )}
      </div>
      {children}
      {info && <p className={classes.info}>{info}</p>}
    </>
  );
};

export default InputApp;
