import React from "react";
import classes from "./RadioBtn.module.scss";
import classNames from "classnames";

const RadioBtn = ({
  label = "selection",
  isSelected,
  onClickHandle,
  classCustom,
  disabled,
}) => {
  return (
    <div
      className={classNames(classes.radioBtnContainer, classCustom, {
        [classes.disabled]: disabled,
      })}
    >
      <label onClick={onClickHandle}>
        <input type="radio" checked={isSelected ? true : false} />
        <div className={classes.checkArea}></div>
        <p>{label}</p>
      </label>
    </div>
  );
};

export default RadioBtn;
