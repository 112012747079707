import React from "react";
import images from "../../commons/images";
import classes from "./IconSearch.module.scss";
import classNames from "classnames";

const IconSearch = ({ classCustom, width = 14, height = 14 }) => {
  return (
    <div
      className={classNames(classes.iconSearch, classCustom)}
      style={{ width, height }}
    >
      <img src={images.iconMcSearch} alt="search" />
    </div>
  );
};

export default IconSearch;
