import React from "react";
import classes from "./ModalSuccess.module.scss";
import ModalLogo from "../../Modals/ModalLogo/ModalLogo";
import ButtonApp from "../../../elements/ButtonApp";

const ModalSuccess = ({ title, msg, btnClickHandle }) => {
  return (
    <ModalLogo>
      <div className={classes.modalSuccess}>
        <h4>{title}</h4>
        {msg && <p>{msg}</p>}
        <ButtonApp
          classCustom={classes.btnSubmit}
          label="Done"
          onClickHandle={btnClickHandle}
        />
      </div>
      <p className={classes.copy}>
        Copyright © 2020 Pelican Express. <span /> All Rights Reserved.
      </p>
    </ModalLogo>
  );
};

export default ModalSuccess;
