import React, { Component } from "react";
import jwtDecode from "jwt-decode";
import htmlToText from "html-to-text";
import classes from "./EditData.module.scss";
import classNames from "classnames";
import Layout from "../../layout/Main";
import FormAddNew from "../../components/FormAddNew";
import ModalState from "../../components/Modals/ModalState";
import HeaderContent from "../../elements/HeaderContent";
import ButtonApp from "../../elements/ButtonApp";
import ProgressBar from "../../elements/ProgressBar";
import Fade from "../../hoc/fade";
import {
  formatDateTime,
  formatDate,
  checkType,
  formatNumberNew,
} from "../../utility";
import { http } from "../../utility/http";
import history from "../../utility/history";

export default class EditData extends Component {
  state = {
    loaded: false,
    userType: 1,
    submitConfig: {
      isSuccess: false,
      modalState: false,
      submitDisabled: true,
      feedback: "",
      progress: {
        active: false,
        count: 0,
      },
    },
    editData: {
      marking_code: "",
      type: {
        is_sea: true,
        is_air: false,
      },
      warehouse_entry: "",
      ETA: "",
      weight: "",
      cubic: "",
      receipt_number: "",
      description: "",
    },
  };

  changeModalState = (state) => {
    const { submitConfig } = this.state;
    this.setState({
      submitConfig: {
        ...submitConfig,
        modalState: state,
      },
    });
  };

  changeDataProgress = (name, value) => {
    const { submitConfig } = this.state;
    let newConfig = { ...submitConfig };
    if (name === "progress") {
      newConfig.progress.count = value;
    } else {
      newConfig.progress.active = value;
    }

    this.setState({
      submitConfig: newConfig,
    });
  };

  submitHandler = async () => {
    const id = this.props.match.params.id;
    const { submitDisabled } = this.state.submitConfig;
    const {
      marking_code,
      type,
      warehouse_entry,
      ETA,
      weight,
      cubic,
      ctns,
      receipt_number,
      description,
    } = this.state.editData;

    let editData = {
      marking_code: marking_code.replace(/\s/g, ""),
      type: type.is_sea ? "sea" : "air",
      warehouse_entry: formatDateTime(warehouse_entry),
      ETA: formatDate(ETA),
      weight,
      cubic,
      ctns,
      receipt_number,
      description,
    };

    let params = {
      method: "PUT",
      path: `cargo/update/${id}`,
      data: editData,
    };
    if (submitDisabled) {
      return;
    }
    this.changeDataProgress("state", true);
    const res = await http(params, true, this.changeDataProgress);
    if (res) {
      this.changeDataProgress("state", false);
      this.changeDataProgress("progress", 0);
      if (res.success) {
        this.changeModalState(true);
        this.setState({
          submitConfig: {
            ...this.state.submitConfig,
            isSuccess: true,
          },
        });
      } else {
        this.setState({
          submitConfig: {
            ...this.state.submitConfig,
            feedback: res.response.response.data.message,
          },
        });
      }
    }
  };

  onChangeHandle = ({ target: { name, value } }) => {
    const { editData } = this.state;
    const setData = (valueField) => {
      this.setState(
        {
          editData: {
            ...editData,
            [name]: valueField,
          },
        },
        this.checkerHandler
      );
    };
    if (name === "warehouse_entry" || name === "ETA") {
      setData(new Date(value));
    } else if (name === "type") {
      const { type } = this.state.editData;
      if (value === "sea") {
        type.is_sea = true;
        type.is_air = false;
      } else {
        type.is_sea = false;
        type.is_air = true;
      }
      setData(type);
    } else {
      setData(value);
    }
  };

  resetHandler = () => {
    this.setState({
      editData: {
        marking_code: "",
        type: {
          is_sea: true,
          is_air: false,
        },
        warehouse_entry: "",
        ETA: "",
        weight: "",
        cubic: "",
        ctns: "",
        description: "",
      },
    });
  };

  goTo = () => {
    history.push("/");
    this.resetHandler();
  };

  checkerHandler = () => {
    const {
      marking_code,
      warehouse_entry,
      ETA,
      weight,
      cubic,
      ctns,
      description,
      receipt_number,
    } = this.state.editData;
    const setConfig = (state, feedback) => {
      this.setState({
        submitConfig: {
          ...this.state.submitConfig,
          submitDisabled: state,
          feedback,
        },
      });
    };

    if (receipt_number) {
      if (receipt_number.length > 20) {
        return setConfig(true, "maximal Tracking No. is 20 character.");
      }
    }
    if (htmlToText.fromString(description).length > 1000) {
      return setConfig(true, "maximal Description is 1000 character.");
    }
    if (
      (marking_code, warehouse_entry, ETA, weight, cubic, ctns, description)
    ) {
      setConfig(false, "");
    } else {
      setConfig(true, "");
    }
  };

  getData = async () => {
    const id = this.props.match.params.id;
    let params = {
      method: "GET",
      path: `cargo/${id}`,
    };
    const res = await http(params);
    if (res) {
      const result = res.response.payload;
      if (res.success) {
        let { type } = { ...this.state.editData };
        if (result.type === "air") {
          type.is_air = true;
          type.is_sea = false;
        } else {
          type.is_air = false;
          type.is_sea = true;
        }
        this.setState({
          loaded: true,
          editData: {
            ...result,
            ETA: new Date(result.ETA * 1000),
            warehouse_entry: new Date(result.warehouse_entry * 1000),
            type,
            weight: result.weight ? formatNumberNew(result.weight) : 0,
            cubic: result.cubic ? formatNumberNew(result.cubic) : 0,
            ctns: result.ctns ? formatNumberNew(result.ctns) : 0,
          },
        });
      }
    }
  };

  initial = async () => {
    let auth = JSON.parse(localStorage.getItem("pelicanX-cms"));
    if (auth) {
      const user = jwtDecode(auth.jwt);
      const type = await checkType(user.type);
      console.log(type);
      this.setState({ userType: type });
    }
    await this.getData();
  };

  componentDidMount() {
    this.initial();
  }
  render() {
    const { loaded, userType, submitConfig, editData } = this.state;
    return (
      <Layout>
        <div className={classes.editData}>
          <HeaderContent title="Edit Data" back={true} />
          <Fade loaded={loaded} wLoader={true}>
            <FormAddNew
              userType={userType}
              data={editData}
              onChangeHandle={this.onChangeHandle}
            />
            {submitConfig.feedback && (
              <p className={classes.error}>{submitConfig.feedback}</p>
            )}
            <div
              className={classNames(classes.submitPlc, {
                [classes.disabled]: submitConfig.submitDisabled,
              })}
            >
              <ButtonApp
                mTop={0}
                label="Cancel"
                border={true}
                onClickHandle={() => history.push("/")}
              />
              <ButtonApp
                mTop={0}
                label="Save"
                onClickHandle={this.submitHandler}
              />
            </div>
          </Fade>

          {submitConfig.modalState && (
            <ModalState
              isSuccess={submitConfig.isSuccess}
              errorMsg={
                submitConfig.isSuccess ? undefined : submitConfig.feedback
              }
              closeBtnHandle={this.goTo}
              successMsg={"Data has been added!"}
            />
          )}
          {submitConfig.progress.active && (
            <ProgressBar count={submitConfig.progress.count} />
          )}
        </div>
      </Layout>
    );
  }
}
