import React from "react";
import classNames from "classnames";
import classes from "./TotalAmount.module.scss";
import InputDate from "../../elements/InputDate";
import { formatNumberDisplay } from "../../utility";
import ButtonApp from "../../elements/ButtonApp";
import IconCalendar from "../../elements/IconCalendar";
import IconSearch from "../../elements/IconSearch";
import Search from "../../elements/Search";

const TotalAmount = ({
  data,
  userType,
  filterTotal,
  onChangeFilter,
  dataSelection,
  changedSelection,
  resetBtnHandler,
  onChangeMarkingDate,
}) => {
  return (
    <div className={classes.totalAmount}>
      <div className={classes.markingCode}>
        <IconSearch />
        <Search
          value={filterTotal.markingCode}
          onChangeHandle={(markingCode) =>
            onChangeMarkingDate(markingCode)
          }
        />
      </div>      
      <div className={classes.inputDate}>
        <InputDate
          value={filterTotal.startDate}
          mTop={0}
          woicon={true}
          onChangeHandle={(value) =>
            onChangeFilter({
              target: {
                value,
                name: "startDate",
              },
            })
          }
        />
        -
        <InputDate
          value={filterTotal.endDate}
          mTop={0}
          woicon={true}
          onChangeHandle={(value) =>
            onChangeFilter({
              target: {
                value,
                name: "endDate",
              },
            })
          }
        />
        <IconCalendar />
      </div>
      <div
        className={classNames(classes.total, {
          [classes.right]: userType === 2 || dataSelection[0].id === 2,
        })}
      >
        <p>Total Weight (kg)</p>
        <h4>{formatNumberDisplay(data.total_weight)}</h4>
      </div>
      <div className={classes.total}>
        <p>Total Cubic (cbm)</p>
        <h4>{formatNumberDisplay(data.total_cubic)}</h4>
      </div>
      <ButtonApp
        disabled={!changedSelection}
        classCustom={classes.btnReset}
        mTop={0}
        label={"Reset"}
        onClickHandle={changedSelection ? resetBtnHandler : null}
      />
    </div>
  );
};

export default TotalAmount;
