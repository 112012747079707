import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./utility/history";
// import Home from "./pages/Home";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AddNew from "./pages/AddNew";
import EditData from "./pages/EditData";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ValidateReset from "./pages/ValidateReset";
import "./App.css";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        localStorage.getItem("pelicanX-cms") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

function App() {
  return (
    <Router history={history}>
      <Switch>
        {/* <Route exact path={"/"} component={Home} /> */}
        <PrivateRoute exact path={"/"} component={Dashboard} />
        <PrivateRoute exact path={"/add-data"} component={AddNew} />
        <PrivateRoute exact path={"/edit-data/:id"} component={EditData} />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/forgot-password"} component={ForgotPassword} />
        <Route exact path={"/reset-password"} component={ResetPassword} />
        <Route exact path={"/resetPassword/:token"} component={ValidateReset} />
        <Route path="*">
          <Redirect to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
