import React from "react";
import classes from "./FormResetPassword.module.scss";
import ModalLogo from "../Modals/ModalLogo/ModalLogo";
import InputApp from "../../elements/InputApp";
import ButtonApp from "../../elements/ButtonApp";
import images from "../../commons/images";

const FormResetPassword = ({ data, onChangeHandle, submitHandler }) => {
  return (
    <ModalLogo
      classCustom={classes.modalReset}
      title="Reset Your Password"
      subTitle="Please enter your new password"
      error={data.feedback ? true : false}
    >
      <form
        className={classes.formReset}
        onSubmit={(e) => {
          e.preventDefault();
          {
            /* submitHandle(); */
          }
        }}
      >
        <InputApp
          type="password"
          label="•••••••••"
          name="newPassword"
          value={data.newPassword}
          mTop={0}
          icon={true}
          srcIcon={images.iconLock}
          classCustom={classes.input}
          onChangeHandle={onChangeHandle}
        />
        {/* <a className={classes.forgot}>Forgot Password?</a> */}
        <ButtonApp
          label="Renew Password"
          mTop={15}
          classCustom={classes.btnSubmit}
          onClickHandle={submitHandler}
        />
      </form>
      <p className={classes.copy}>
        Copyright © 2020 Pelican Express. <span /> All Rights Reserved.
      </p>
    </ModalLogo>
  );
};

export default FormResetPassword;
