import React, { useState, useEffect } from "react";
import classes from "./FormAddNew.module.scss";
import htmlToText from "html-to-text";
import classNames from "classnames";
import InputWithLabel from "../../elements/InputWithLabel";
import InputDateLabel from "../../elements/InputDateLabel";
import RadioWithLabel from "../../elements/RadioWithLabel";
import RichWithLabel from "../../elements/RichWithLabel";
import images from "../../commons/images";

const FormAddNew = ({ userType, data, onChangeHandle }) => {
  const { is_sea, is_air } = data.type;
  const [dataRadio, setDataRadio] = useState([
    {
      id: 1,
      label: "Laut",
      isSelected: is_sea ? !is_air : false,
      disabled: userType === 1 ? false : true,
      clickHandle: () => {
        onChangeHandle({
          target: {
            value: "sea",
            name: "type",
          },
        });
      },
    },
    {
      id: 2,
      label: "Udara",
      disabled: userType === 1 ? false : true,
      isSelected: is_air ? !is_sea : false,
      clickHandle: () => {
        onChangeHandle({
          target: {
            value: "air",
            name: "type",
          },
        });
      },
    },
  ]);
  useEffect(() => {
    let newDataRadio = [...dataRadio];
    if (userType === 3) {
      newDataRadio[0].isSelected = false;
      newDataRadio[1].isSelected = true;
    }
    setDataRadio(newDataRadio);
  }, []);

  const onChangeRadio = (index) => {
    console.log(dataRadio[index].disabled);
    console.log(userType);
    if (dataRadio[index].disabled) {
      return;
    }
    let newData = [...dataRadio];
    newData.forEach((item) => {
      item.isSelected = false;
    });
    newData[index].isSelected = true;
    setDataRadio(newData);
    newData[index].clickHandle();
  };

  return (
    <div className={classes.formAddNew}>
      <div className={classes.section}>
        <InputWithLabel
          value={data.marking_code}
          mTop={0}
          classCustom={classes.inputContainer}
          label="Marking Code"
          placeholder="eg. PPPXXX56789"
          onChangeHandle={(e) =>
            onChangeHandle({
              target: {
                value: e.target.value,
                name: "marking_code",
              },
            })
          }
        />
        <RadioWithLabel
          label="Type"
          labels={["Laut", "Udara"]}
          data={dataRadio}
          onChangeRadio={onChangeRadio}
          userType={userType}
        />
      </div>
      <div className={classes.section}>
        <InputDateLabel
          value={data.warehouse_entry}
          classCustom={classes.inputContainer}
          label="Warehouse Entry Date"
          onChangeHandle={(value) => {
            console.log(value);
            onChangeHandle({
              target: {
                value,
                name: "warehouse_entry",
              },
            });
          }}
        />
        <InputDateLabel
          value={data.ETA}
          classCustom={classes.inputContainer}
          label="ETA"
          onChangeHandle={(value) =>
            onChangeHandle({
              target: {
                value,
                name: "ETA",
              },
            })
          }
        />
      </div>
      <div className={classNames(classes.section, classes.measure)}>
        <InputWithLabel
          value={data.weight}
          mTop={0}
          classCustom={classes.inputContainer}
          label="Weight (kg)"
          placeholder="1,355"
          info="*numeric only"
          isNumber={true}
          srcIcon={images.iconDate}
          onChangeNumber={(e) =>
            onChangeHandle({
              target: {
                value: e.target.value,
                name: "weight",
              },
            })
          }
        />
        <InputWithLabel
          value={data.cubic}
          mTop={0}
          classCustom={classes.inputContainer}
          label="Cubic (m³)"
          placeholder="1,355"
          info="*numeric only"
          isNumber={true}
          onChangeNumber={(e) =>
            onChangeHandle({
              target: {
                value: e.target.value,
                name: "cubic",
              },
            })
          }
        />
      </div>
      <div className={classes.section}>
        <InputWithLabel
          value={data.ctns}
          mTop={0}
          classCustom={classes.inputContainer}
          label="Containers (ctns)"
          placeholder="0"
          info="*numeric only"
          isNumber={true}
          onChangeNumber={(e) =>
            onChangeHandle({
              target: {
                value: e.target.value,
                name: "ctns",
              },
            })
          }
        />
        <InputWithLabel
            value={data.receipt_number}
            mTop={0}
            classCustom={classes.inputContainer}
            label="Tracking No."
            placeholder="Nomor Resi"
            onChangeHandle={(e) =>
              onChangeHandle({
                target: {
                  value: e.target.value,
                  name: "receipt_number",
                },
              })
            }
          />
      </div>
      <div className={classes.section}>
        {/* <TextAreaLabel
          value={data.description}
          mTop={0}
          rows={5}
          classCustom={classes.inputContainer}
          label="Description"
          placeholder="About the item"
          onChangeHandle={(e) =>
            onChangeHandle({
              target: {
                value: e.target.value,
                name: "description",
              },
            })
          }
        /> */}
        <RichWithLabel
          label={`Description`}
          counter={`${htmlToText.fromString(data.description).length} / 1000`}
          value={data.description}
          height={300}
          classCustom={classNames(classes.richText, {
            [classes.over]:
              htmlToText.fromString(data.description).length > 1000,
          })}
          onChangeHandler={(value) => {
            onChangeHandle({
              target: {
                value,
                name: "description",
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default FormAddNew;
