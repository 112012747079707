import React from "react";
import { Transition } from "react-transition-group";
import Loader from "../elements/Loader";
import classNames from "classnames";
import Aux from "./aux";

const Fade = ({
  loaded,
  delay = 100,
  transition = 300,
  customLoader,
  children,
  classCustom,
  wLoader,
}) => {
  const defaultStyle = {
    transition: `opacity ${transition}ms ease-in-out`,
    opacity: 0,
  };
  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };
  return (
    <Aux>
      <Transition in={loaded} timeout={transition} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className={classNames({ [classCustom]: classCustom })}
          >
            {children}
          </div>
        )}
      </Transition>

      {!loaded &&
        wLoader &&
        (customLoader ? <Aux>{customLoader}</Aux> : <Loader />)}
    </Aux>
  );
};

export default Fade;
