import React from "react";
import classes from "./RichWithLabel.module.scss";
import classNames from "classnames";
import RichText from "../../elements/RichText";

const RichWithLabel = ({
  label = "label",
  counter,
  init,
  value,
  height,
  onChangeHandler,
  disabled,
  classCustom,
}) => {
  return (
    <div className={classNames(classes.richWithLabel, classCustom)}>
      <p>
        {label} {counter && <span>( {counter} )</span>}
      </p>
      <RichText
        init={init}
        value={value}
        height={height}
        onChangeHandler={onChangeHandler}
        disabled={disabled}
        classCustom={classes.richText}
      />
    </div>
  );
};

export default RichWithLabel;
