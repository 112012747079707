import React from "react";
import classes from "./Header.module.scss";
import classNames from "classnames";
import images from "../../commons/images";
import BreadCrumb from "../../elements/BreadCrumb";
import history from "../../utility/history";

const Header = ({
  userType,
  classCustom,
  breadCrumbData,
  stateSideNav,
  changeStateSideNav,
  logoutHandle,
}) => {
  const checkingUser = () => {
    switch (userType) {
      case 2:
        return { name: "Admin Laut" };
      case 3:
        return { name: "Admin Udara" };
      default:
        return { name: "Super Admin" };
    }
  };
  return (
    <div className={classNames(classes.header, classCustom)}>
      <div className={classes.headerContent}>
        <div className={classes.logo}>
          <img src={images.logoPelicanColor} alt="Pelican Express" />
        </div>
        {breadCrumbData && <BreadCrumb breadCrumbData={breadCrumbData} />}
        <div className={classes.welcome}>
          <h4>Hi, {checkingUser().name}</h4>
          <div className={classes.btnLogout} onClick={logoutHandle}>
            <img src={images.iconLogout} alt="logout" />
          </div>
        </div>
        <div
          className={
            classes.btnMobile + " " + [stateSideNav ? classes.active : ""]
          }
          onClick={() => changeStateSideNav(!stateSideNav)}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
};

export default Header;
